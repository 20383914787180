export function useLoading() {
	const isLoading = ref(false);

	async function inLoading<T = void>(fn: () => T | Promise<T>) {
		isLoading.value = true;
		try {
			return await fn();
		} finally {
			isLoading.value = false;
		}
	}

	return { isLoading, inLoading };
}

type UNotificationProps = {
	id: number | string;
	title: string;
	description: string;
	timeout: number;
	callback: () => void;
};

export function useNotification(
	defaultConfig: Pick<UNotificationProps, 'id'> & Partial<Omit<UNotificationProps, 'callback'>> = {
		id: crypto.randomUUID(),
		timeout: 5000,
	},
) {
	const notificationId = defaultConfig.id;

	const notificationTitle = useState<string>(`${notificationId}.notification.title`, () => '');
	const notificationDescription = useState<string>(`${notificationId}.notification.description`, () => '');

	const notificationTimeout = useState<number>(`${notificationId}.notification.timeout`, () => defaultConfig.timeout ?? 5000);

	const notificationIsDisabled = useState<boolean>(`${notificationId}.notification.isDisabled`, () => true);
	const notificationCallback = () => {
		notificationIsDisabled.value = true;
	};
	onBeforeUnmount(() => {
		notificationIsDisabled.value = true;
	});

	async function showNotification(config: Partial<Omit<UNotificationProps, 'id' | 'callback'>>) {
		notificationTitle.value = config.title ?? '';
		notificationDescription.value = config.description ?? '';

		notificationIsDisabled.value = false;

		const newTimeout = config.timeout ?? notificationTimeout.value ?? 5000;

		notificationTimeout.value = 0;
		await nextTick(() => {
			notificationTimeout.value = newTimeout;
		});
	}

	const isDisabled = readonly(notificationIsDisabled);
	const isEnabled = computed(() => !unref(isDisabled));

	const bind = readonly(
		reactive({
			id: notificationId,

			title: notificationTitle,
			description: notificationDescription,
			timeout: notificationTimeout,

			callback: notificationCallback,
		}),
	);

	return {
		bind: bind,
		notification: bind,
		props: bind,
		attributes: bind,

		isDisabled,
		disabled: isDisabled,
		hide: isDisabled,

		isEnabled: isEnabled,
		enabled: isEnabled,
		show: isEnabled,

		showNotification,
	};
}

export function useDefaultNotifications(baseId: string) {
	const successNotification = useNotification({ id: `${baseId}-success-notification` });

	const errorNotification = useNotification({ id: `${baseId}-error-notification` });

	const warningNotification = useNotification({ id: `${baseId}-warning-notification` });

	return reactive({
		success: successNotification,
		error: errorNotification,
		warning: warningNotification,
	});
}

export function useWhatsappContactLink(message?: string) {
	if (!message) {
		message = 'Olá! Estou interessado no sistema de gamificação e ranking. Pode me passar mais detalhes?';
	}

	const phoneNumber = '5531988554222'; // Substitua pelo número desejado
	const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
	return readonly(ref(whatsappLink));
}
